<template>
    <section>
        <modal ref="modalDetallesPostulacion" titulo="Justificación" tamano="modal-lg" no-aceptar>
            <div class="row mx-0 pl-4">
                <div class="col-auto f-600 text-general f-600 f-17 px-0">
                    {{ detalle.tipo_nombre }}
                </div>
                <div class="col-auto ml-auto text-muted2 f-17">
                    {{ detalle.created_at | helper-fecha('DD-MMMM-Y') }}
                </div>
            </div>
            <div class="row mx-0 pl-4">
                {{ detalle.justificacion }}
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    props:{
        detalle:{
            type:Object,
            default: a => {}
        }
    },
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalDetallesPostulacion.toggle();
        }
    }
}
</script>
